<template lang="pug">
b-modal#modal-choice-news(
  v-model="modalShow"
  title="選擇播放清單", 
  title-class="font-18", 
  body-class="p-0" 
  size="lg"
)
  .choice_num 已選擇
    span.blue {{ checkRowId.length }}
    span 個項目
  .news-list 
    // Table
    .table-responsive.mb-0
      b-table(ref='typePageTable' :items='searchPlayList' :fields='fields' hover='' :per-page='perPage' :current-page='currentPage' :sort-desc.sync='sortDesc' :filter='filter' :filter-included-fields='filterOn' @filtered='onFiltered')
        //- Checkbox
        template(#head(selected)='data')
          b-button(variant='link' @click='onSelectAllRowId')
            | {{ isAllSelected ? '取消全選' : '全選' }}
        template(#cell(selected)='data')
          b-form-checkbox(v-model='checkRowId' :name='data.item.id' :value='data.item.id' :id='data.item.id')
        //- 封面
        template(#cell(image)='data')
          img.list_image(:src="data.item.image")
       
        

  template(#modal-footer='{ ok, cancel, hide }')
    b-button(size='md' variant='danger' @click='cancel()')
      | 取消
    b-button(size='md' variant='success' @click='clickOk()')
      | 確定

</template>
<script>
import draggable from 'vuedraggable'
export default {
  props: ['show_status', 'default_list','choice_type'],
  name: "PlayListChoice",
  data () {
    return {
      modalShow: false,

      checkRowId: [],
      tableData: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortDesc: false,
      fields: [
        {
          key: "selected",
          label: "全選",
          class: 'width_100 center'
        },
        {
          key: "image",
          label: "封面",
          class: 'width-auto center'
        },
        {
          key: "title",
          label: "播放清單名稱",
          class: 'width-auto center'
        },
      ],
    }
  },
  watch: {
    show_status (_status) {
      this.modalShow = _status
    },
    default_list (_value){
      var vm = this
      if (_value.length > 0) {
        _value.forEach(function (_item) {
            vm.checkRowId.push(_item.liveplaylist_id)
        })
      }
      console.log(this.checkRowId);
    }
  },
  created () {

  },
  computed:{
    rows () {
      return this.tableData.length;
    },
    isAllSelected () {
      return this.rows !== 0 && this.rows === this.checkRowId.length
    }
  },
  methods: {
    // 獲得播放清單列表
    searchPlayList (_table, _callback) {
      let vm = this
      this.$root.apis.getPlayList(
        {
          page: 1,
          type: vm.choice_type === 'special_plan' ? 2 : 1,
          sort_type: 1
        },
        function (_response) {
          if (typeof _callback === 'function') {
            vm.tableData = _response.body.items
            _callback(vm.tableData)
          }
          console.log(_response.body.items);
        }
      )
    },

    onSelectAllRowId () {
      if (this.isAllSelected) {
        this.checkRowId = []
      } else {
        this.checkRowId = this.tableData.map(_data => _data.id)
      }
    },


    clickOk () {
      
      this.$emit('returnPlayList', this.checkRowId)
      this.checkRowId =[]
      this.tableData =[]
      this.modalShow = false
    },

    /**
     * Search the table data with search input
     */
     onFiltered (filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
  components: {
    draggable
  },
}
</script>
<style lang="sass" scoped>
.category-list
  border-right: 1px solid #e5e8eb
  width: 149px
  max-height: 80vh
  overflow: auto
  .category-item
    padding: 1rem 1.5rem
    color: #6b7280e6
    cursor: pointer
    &.active
      background: #eee
.types-list
  display: flex
  gap: 5px
.choice-main
  flex: 1
  padding: 1rem 0.75rem
  max-height: 80vh
  overflow: auto
::v-deep .width_100
  width: 150px !important
.news-list 
  margin: 20px 20px
  width: calc(100% - 40px)
  box-shadow: 0 0 10px #eee
  .list_image
    width: 100px   
    object-fit: cover
.choice_num
  background: #ff846e4a
  padding: 0.5rem
  margin: 20px 20px
  width: calc(100% - 40px)
  border-radius: 5px
  .blue
    color: #1e40b0
    margin: 0 5px

::v-deep thead
  background: rgba(226,232,240,1)
::v-deep .table th, .table td, ::v-deep .center
  vertical-align: middle    
</style>
