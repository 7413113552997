<template lang="pug">
Layout
  PageHeader(:title="title", :items="items")
  .row
    .col-lg-12
      .card
        .row 
          .col-9
            .card-body
              .table-responsive
                table.table.table-bordered.mb-20
                  thead
                    tr
                      th 版塊名稱
                      th 內容配置
                      th 
                  tbody
                    //-  大直播
                    tr
                      th(scope="row") 大直播
                      td
                        .col-lg-12
                          .form-group.mb-2
                            input.form-control(v-model="live_video_config.youtube_video_uri", type="text", placeholder="請輸入YT對應播放清單網址(請勿輸入短網址)")
                          .radio-group.col-auto.text-left.pl-0
                            b-form-radio-group#radio-group-1(v-model="live_video_config.open_chatroom", :options="radio_options", text-field="label", name="radio-options")
                      td 
                        b-button.ml-1.width-lg(variant="dark" @click="updateLiveVideoConfig()") 更新

                    //-  熱門直播
                    tr
                      th(scope="row") 熱門直播
                      td
                        .col-lg-12
                          .form-group.mb-2
                            input.form-control(v-model="hot_live_config.youtube_playlist_uri", type="text", placeholder="請輸入YT對應播放清單網址(請勿輸入短網址)")
                          .form-group.mb-0
                            multiselect(v-model="hot_live_sort", :options="options1", placeholder="請選擇排序方式", track-by="value", label="label", select-label="")                        
                      td 
                        b-button.ml-1.width-lg(variant="dark" @click="updateHotLiveConfig()") 更新

                    //-  特別企劃
                    tr
                      th(scope="row") 特別企劃
                      td.text-left
                        b-button.ml-2.width-lg(variant="secondary"  @click="openChoice('special_plan')") 選擇播放清單
                        span.ml-3(v-if="special_plan_playlists.length > 0") 已選擇
                          span.focus_span {{ special_plan_playlists.length }}
                          span 播放清單
                        span.ml-3(v-else) 請選擇播放清單
                        .col-lg-12.mt-2
                          .form-group.mb-0.pl-0
                            multiselect(v-model="special_plan_sort", :options="options2", placeholder="請選擇排序方式", track-by="value", label="label", select-label="")
                      td 
                        b-button.ml-1.width-lg(variant="dark" @click="updateSpecialPlanConfig()") 更新

                    //-  直播節目
                    tr
                      th(scope="row") 直播節目
                      td.text-left
                        b-button.ml-2.width-lg(variant="secondary"  @click="openChoice('live_program')") 選擇播放清單
                        span.ml-3(v-if="live_program_playlists.length > 0") 已選擇
                          span.focus_span {{ live_program_playlists.length }}
                          span 播放清單
                        span.ml-3(v-else) 請選擇播放清單
                        .col-lg-12.mt-2
                          .form-group.mb-0.pl-0
                            multiselect(v-model="live_program_sort", :options="options2", placeholder="請選擇排序方式", track-by="value", label="label", select-label="")
                      td 
                        b-button.ml-1.width-lg(variant="dark" @click="updateLiveProgramConfig()") 更新


  ChoicePlayList(:choice_type="choice_type" :show_status="show_status" :default_list="default_list" @returnPlayList="onPlayListUpdate")
      
</template>
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/Page-header";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import ChoicePlayList from "@/components/ChoicePlayList"
export default {
  head() {
    return {
      title: `${this.title} | Minton - Nuxtjs Responsive Admin Dashboard Template`,
    };
  },
  data() {
    return {
      title: "專區首頁編輯",
      items: [
        {
          text: "直播管理",
          href: "/livestream",
        },
        {
          text: "專區首頁編輯",
          active: true,
        }
      ],
      radio_options: [
        { label: "顯示聊天室", value: true },
        { label: "不顯示聊天室", value: false },
      ],
      options1: [
        { label: "熱門度", value: 'hot' },
        { label: "發布時間新到舊", value: 'postAt' },
        { label: "即將直播", value: 'comming' },
      ],
      options2: [
        { label: "最近更新新到舊", value: 'updateAt' },
        { label: "建立時間新到舊", value: 'createAt' }
      ],

      // 排序欄位
      hot_live_sort: null,
      special_plan_sort: null,
      live_program_sort: null,
      
      live_video_config:{
        youtube_video_uri:'',
        open_chatroom: false
      },
      hot_live_config:{
        youtube_playlist_uri:'',
        sort_type:''
      },
      special_plan_config:{
        lists:[],
        sort_type: '',
      },
      live_program_config:{
        lists:[],
        sort_type:''
      },

      special_plan_playlists:[],
      live_program_playlists:[],


      show_status: false,
      choice_type: '',
      default_list: []
    };
  },
  watch:{
    'hot_live_sort'(_val){
      
      if (_val) {
        this.hot_live_config.sort_type = _val.value
      }else{
        this.hot_live_config.sort_type = ''
      }
    },
    'special_plan_sort'(_val){
      console.log(_val);
      if (_val) {
        this.special_plan_config.sort_type = _val.value
      }else{
        this.special_plan_config.sort_type = ''
      }
     
    },
    'live_program_sort'(_val){
      if (_val) {
        this.live_program_config.sort_type = _val.value
      }else{
        this.live_program_config.sort_type = ''
      }
    },
  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
    DatePicker,
    ChoicePlayList
  },
  created(){
    this.getLiveConfig()
  },
  mounted(){
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      this.show_status = false
    })
  },
  methods:{
    openChoice(_type){
      
      this.choice_type = _type
      if (this.choice_type === 'special_plan') {
        this.default_list = this.special_plan_config.lists
      }
      if (this.choice_type === 'live_program') {
        this.default_list = this.live_program_config.lists
      }
      this.show_status = true
      
    },
    // 從選擇器選定影片播放清單
    onPlayListUpdate(_list) {
      this.special_plan_playlists = []
      this.live_program_playlists = []
      if (this.choice_type === 'special_plan') {
        this.special_plan_playlists = _list
      }
      if (this.choice_type === 'live_program') {
        this.live_program_playlists = _list
      }
    },

    // 獲取直播設置
    getLiveConfig () {
      let vm = this
      this.special_plan_playlists = []
      this.live_program_playlists = []

      this.$root.apis.getLiveConfig(function (_response) {
        vm.live_video_config = _response.body.livevideo
        vm.hot_live_config = _response.body.hotlive
        vm.special_plan_config = _response.body.specialplan
        vm.live_program_config = _response.body.liveprogram

        if (vm.live_program_config.lists.length !== 0){
          vm.live_program_config.lists.forEach(function (_item) {
              vm.live_program_playlists.push(_item.liveplaylist_id)
          })
        }
        if (vm.special_plan_config.lists.length !== 0){
          vm.special_plan_config.lists.forEach(function (_item) {
              vm.special_plan_playlists.push(_item.liveplaylist_id)
          })
        }
        

        if (vm.hot_live_config.sort_type) {
          if (vm.hot_live_config.sort_type === 'hot') {
            vm.hot_live_sort = vm.options1[0]
          }
          if (vm.hot_live_config.sort_type === 'postAt') {
            vm.hot_live_sort = vm.options1[1]
          }
          if (vm.hot_live_config.sort_type === 'comming') {
            vm.hot_live_sort = vm.options1[2]
          }
        }

        if (vm.special_plan_config.sort_type) {
          if (vm.special_plan_config.sort_type === 'updateAt') {
            vm.special_plan_sort = vm.options2[0]
          }
          if (vm.special_plan_config.sort_type === 'createAt') {
            vm.special_plan_sort = vm.options2[1]
          }
        }

        if (vm.live_program_config.sort_type) {
          if (vm.live_program_config.sort_type === 'updateAt') {
            vm.live_program_sort = vm.options2[0]
          }
          if (vm.live_program_config.sort_type === 'createAt') {
            vm.live_program_sort = vm.options2[1]
          }
        }
        
      }, this.getLiveConfigError)
    },
    // 獲取直播設置失敗
    getLiveConfigError (_error) {
      let error = _error.body.data
      this.$root.common.showErrorDialog(error)
    },

    // 更新大直播影片設定
    updateLiveVideoConfig () {
      this.$root.apis.updateLiveVideoConfig(this.live_video_config, this.$root.common.showSingleNotify("更新成功！"), this.errorFunction)
    },

    // 更新熱門直播設定
    updateHotLiveConfig () {
      this.$root.apis.updateHotLiveConfig(this.hot_live_config, this.$root.common.showSingleNotify("更新成功！"), this.errorFunction)
    },

    // 更新特別企劃設定
    updateSpecialPlanConfig () {
      const data = {
        sort_type: this.special_plan_config.sort_type,
        playlists: this.special_plan_playlists
      }
      this.$root.apis.updateSpecialPlanConfig( data, this.updateConfigSuccess, this.errorFunction)
    },

    // 更新直播節目設定
    updateLiveProgramConfig () {
      const data = {
        sort_type: this.live_program_config.sort_type,
        playlists: this.live_program_playlists
      }
      this.$root.apis.updateLiveProgramConfig( data, this.updateConfigSuccess, this.errorFunction )
    },
    
    updateConfigSuccess(){
      this.getLiveConfig()
      this.$root.common.showSingleNotify("更新成功！")
    },

    errorFunction(){
      this.$root.common.showErrorNotify('有欄位未填寫')
    }

  }
};
</script>
<style lang="sass" scoped>
.table
  thead
    th
      vertical-align: middle
      text-align: center
  tbody
    tr
      th,td
        vertical-align: middle
        text-align: center
.focus_span
  color: #1e40b0
  margin: 0 4px

</style>
